import React, { Component, Fragment } from "react";
import { Col, Row, Button, Form, FormGroup, Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import {
  getSLIM4APIUrl,
  removeUserSession,
  getCookie,
} from "../../utils/Common";
import { Context } from "../../Context";
import md5 from "js-md5";
import { encode } from "js-base64";
import { getRequestHeaders } from "utils/Api";
import BlockLoader from "components/BlockLoader";

class Login extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      its_id: "",
      username: "",
      password: "",
      redirectToReferrer: false,
      loggedIn: false,
      role: 0,
      loading: false,
      errorMessage: null,
    };
  }

  handleChange = (event) => {
    this.setState({ username: event.target.value });
  };
  loginUser = () => {
    if (
      !(
        this.state.its_id &&
        this.state.password &&
        this.state.password == "Bchet@515253!"
      )
    ) {
      this.setState({ errorMessage: "ITS ID or Password Is Incorrect" });
      return false;
    }
    removeUserSession();
    const pass = md5(`${this.state.its_id}tlbilm@12345678+515253`);
    const token = encode(`${this.state.its_id}:${pass}`);
    localStorage.setItem("fa-token", token);
    this.setState({ loading: true });
    fetch(`${getSLIM4APIUrl()}`, {
      headers: getRequestHeaders(),
    })
      .then((res) => res.json())
      .then((json) => {
        document.cookie = `user_its=${json.its_id}`;
        document.cookie = `ver=${pass}`;
        document.cookie = `role=${json.role}`;
        document.cookie = `tanzeem_id=${json.tanzeem_id}`;
        this.setState({
          user: json,
          loading: false,
          role: String(json.role),
          redirectToReferrer: true,
        });
      });
  };

  render() {
    const { redirectToReferrer, role } = this.state;
    let amilId = "5";
    if (role === "3") {
      amilId = getCookie("tanzeem_id");
    }
    if (redirectToReferrer === true) {
      if (role === "8") return <Redirect to="/co/dashboard" />;
      if (role === "7") return <Redirect to="/admin/dashboard" />;
      if (role === "6") return <Redirect to="/exec-trustee/dashboard" />;
      if (role === "5") return <Redirect to="/trustee/dashboard" />;
      if (role === "4") return <Redirect to="/accounts/dashboard" />;
      if (role === "3") return <Redirect to={`/amil-saheb/home`} />;
      if (role === "2") return <Redirect to="/counsellor/dashboard" />;
      if (role === "-1") return <Redirect to="/not-authorised" />;
      return <Redirect to="/applicant/dashboard" />;
    }

    return (
      <BlockLoader loading={this.state.loading}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ maxWidth: 350, margin: "10px 20px" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.loginUser();
              }}
            >
              <Row>
                <Col>
                  <h3>Login</h3>
                </Col>
              </Row>
              <Row>
                <Col>ITS ID:</Col>
                <Col>
                  <Input
                    required
                    type="number"
                    value={this.state.its_id}
                    onChange={(e) => this.setState({ its_id: e.target.value })}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>Password:</Col>
                <Col>
                  <Input
                    type="password"
                    required
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <p className="text-danger">{this.state.errorMessage}</p>
              <Button type="submit" color="info">
                Login
              </Button>
            </form>
          </div>
        </div>
      </BlockLoader>
    );
  }
}
export default Login;
