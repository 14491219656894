import { Redirect, useHistory } from "react-router-dom";
import { getSLIM4APIUrl, getCookie } from "utils/Common";
import { getRequestHeaders } from "utils/Api";
import { useEffect, useState, useContext } from "react";
import { Context } from "../../Context";

const ItsLoginUser = () => {
  const [redirectPath, setRedirectPath] = useState();
  const getContext = useContext(Context);

  const history = useHistory();
  // this.setState({ loading: true });
  useEffect(() => {
    if (redirectPath === "3") {
      history.push(`/amil-saheb/home`);
    } else if (redirectPath === "8") {
      history.push("/co/dashboard");
    } else if (redirectPath === "7") {
      history.push("/admin/dashboard");
    } else if (redirectPath === "6") {
      history.push("/exec-trustee/dashboard");
    } else if (redirectPath === "5") {
      history.push("/trustee/dashboard");
    } else if (redirectPath === "4") {
      history.push("/accounts/dashboard");
    } else if (redirectPath === "2") {
      history.push("/counsellor/dashboard");
    } else if (redirectPath == -1) {
      history.push("/not-authorised");
    } else if(redirectPath) {
      history.push("/applicant/dashboard");
    }
  }, [redirectPath]);

  useEffect(() => {
    getContext.setToken();
    fetch(`${getSLIM4APIUrl()}`, {
      headers: getRequestHeaders(),
    })
      .then((res) => res.json())
      .then((json) => {
        const user = {
          its_id: json.its_id,
          role: json.role,
          tanzeem_id: json.tanzeem_id,
        };
        localStorage.setItem("user", JSON.stringify(user));

        let role = String(json.role);
        setRedirectPath(role);
      });
  }, []);
  return <></>;
};

export { ItsLoginUser };
