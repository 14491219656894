import React, { Component, Fragment, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { uniqBy } from "lodash";
import { rolesConfig } from "../config/roles";
import * as Routes from "./index";
import { getRequestHeaders } from "utils/Api";
import { isAdmin, getUser, getSLIM4APIUrl } from "./Common";
import { Context } from "Context";
import History from "../utils/History";
import axios from "axios";

class PrivateRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedRoutes: [],
      redirect: false,
      role: 0,
    };
  }
  static contextType = Context;

  componentDidMount() {
    // const getContext = useContext(Context);
    const token = this.context.setToken();
    const _isAdmin = isAdmin();

    if (!token) {
      // this URL is for bchet.talabulilm
      // window.location.replace(
      //   "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=YmNoZXQudGFsYWJ1bGlsbS5jb20="
      // );
      // this URL is for testbchet.talabulilm
      window.location.replace(
        "https://www.its52.com/Login.aspx?OneLogin=MHB&tlbre=dGVzdGJjaGV0LnRhbGFidWxpbG0uY29t"
      );
      this.setState({ redirect: true });
    }
    axios.defaults.headers.common["Authorization"] = "Basic " + token;

    if (this.props.location.pathname === "/") {
      fetch(`${getSLIM4APIUrl()}`, {
        headers: getRequestHeaders(),
      })
        .then((res) => res.json())
        .then((json) => {
          this.setState({ role: json.role });
          let roles = String(json.role);
          if (roles === "3") {
            this.props.history.push(`/amil-saheb/home`);
          } else if (roles === "8") {
            this.props.history.push("/co/dashboard");
          } else if (roles === "7") {
            this.props.history.push("/admin/dashboard");
          } else if (roles === "6") {
            this.props.history.push("/exec-trustee/dashboard");
          } else if (roles === "5") {
            this.props.history.push("/trustee/dashboard");
          } else if (roles === "4") {
            this.props.history.push("/accounts/dashboard");
          } else if (roles === "2") {
            this.props.history.push("/counsellor/dashboard");
          } else if (roles === "-1") {
            this.props.history.push("/not-authorised");
          } else {
            this.props.history.push("/applicant/dashboard");
          }
        })
        .then((err) => {
          console.log(err);
        });
    }

    if (Math.abs(isAdmin()) === 1) {
      let roles = ["students", "admin"];
      roles = ["common", ...roles];
      let allowedRoutes = roles.reduce((acc, role) => {
        return [...acc, ...rolesConfig[role].routes];
      }, []);

      // For removing duplicate entries, compare with 'url'.
      allowedRoutes = uniqBy(allowedRoutes, "url");
      this.setState({ allowedRoutes });
    } else {
      let roles = ["students", "admin"];
      roles = ["common", ...roles];
      let allowedRoutes = roles.reduce((acc, role) => {
        return [...acc, ...rolesConfig[role].routes];
      }, []);

      // For removing duplicate entries, compare with 'url'.
      allowedRoutes = uniqBy(allowedRoutes, "url");
      this.setState({ allowedRoutes });
    }
  }
  RouterRedirect = () => {
    if (this.props.location.pathname === "/") {
      let userDetail = getUser();
      let path = "";
      switch (String(userDetail?.role)) {
        case "8":
          path = "/co/dashboard";
          break;
        case "7":
          path = "/admin/dashboard";
          break;
        case "6":
          path = "/exec-trustee/dashboard";
          break;
        case "5":
          path = "/trustee/dashboard";
          break;
        case "4":
          path = "/accounts/dashboard";
          break;
        case "3":
          path = `/amil-saheb/home`;
          break;
        case "2":
          path = "/counsellor/dashboard";
          break;
        case "-1":
          path = "/not-authorised";
          break;
        default:
          path = "/applicant/dashboard";
          break;
      }
      History.replace(path);
      return <Redirect to={path} />;
    }
    return <Redirect to={this.props.location.pathname} />;
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/its-login" />;
    }
    // if (this.context.searchedList && this.context.searchedList.length > 0) {
    //   this.props.history.push("/header/search");
    // }
    return (
      <Fragment>
        <Switch>
          {this.state.allowedRoutes.map((route) => (
            <Route
              exact
              key={route.url}
              component={Routes[route.component]}
              path={`${this.props.match.path}${route.url}`}
            />
          ))}
          {/* {<Route component={NotFound} />} */}
          {/* {<this.RouterRedirect />} */}
        </Switch>
      </Fragment>
    );
  }
}

export default PrivateRoutes;
